import { useActiveWeb3React } from 'hooks'
import { useCallback } from 'react'
import { faucetClaimKUSD } from 'utils/fetch/faucet'
import { Alert, message } from 'antd'
import { Box } from '@mui/material'
import { ChainListMap } from 'constants/chain'
import { useWalletModalToggle } from 'state/application/hooks'

export default function TopAlert() {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const claim = useCallback(() => {
    if (!chainId || !account) return
    faucetClaimKUSD(chainId, account)
      .then(res => {
        if (res.data.code !== 0) {
          message.warning(res.data.msg)
          return
        }
        message.success('Claim KUSD success on ' + ChainListMap[chainId].name)
      })
      .catch(() => {
        message.error('Network error')
      })
  }, [account, chainId])

  return (
    <Box sx={{ position: 'fixed', width: '100%', zIndex: 999 }}>
      <Alert
        message={
          <>
            Claim test token KUSD to {account ? account : '0x00'} click{' '}
            <span
              style={{ color: '#3898FC', cursor: 'pointer' }}
              onClick={() => (account ? claim() : toggleWalletModal())}
            >
              here.
            </span>
          </>
        }
        type="info"
      />
    </Box>
  )
}
