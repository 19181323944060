import './pc.less'

// import icon01 from '../../assets/images/icon-membership.svg'
// import icon02 from '../../assets/images/icon-dao.svg'
// import icon03 from '../../assets/images/icon-invest.svg'
import { useActiveWeb3React } from 'hooks'
import { useWalletModalToggle } from 'state/application/hooks'
import CreateSelectModal from './CreateSelectModal'
import useModal from 'hooks/useModal'
import Button from 'components/Button/Button'
import { Box } from '@mui/material'

export default function Index() {
  // const cardItems = [
  //   {
  //     icon: icon01,
  //     title: 'Membership DAO',
  //     desc: 'Issue DAO governance tokens to represent membership and voting power'
  //     // onClick: () => history.push('/building/settings/memberShip'),
  //     // action: 'Build'
  //   },
  //   {
  //     icon: icon02,
  //     title: 'Project DAO',
  //     desc: 'Launch a project DAO with transparent and accountable crowdfunding and token distribution process'
  //     // onClick: () => history.push('/building/settings/dao'),
  //     // action: 'Build'
  //   },
  //   {
  //     icon: icon03,
  //     title: 'Investment',
  //     desc: 'Manage investment decisions and fund usage based on community votes'
  //     // onClick: () => history.push('/building/settings/invest'),
  //     // action: 'Build'
  //   }
  // ]
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const { showModal, hideModal } = useModal()
  return (
    <main className="home">
      <h1>Create a DAO within a few clicks</h1>
      <p>Web 3.0 infrastructures to power your Decentralized Autonomous Organizations</p>
      <Box mt={'32px'}>
        {account ? (
          <Button width="120px" height="48px" onClick={() => showModal(<CreateSelectModal hide={hideModal} />)}>
            Build
          </Button>
        ) : (
          <Button width="150px" height="48px" onClick={toggleWalletModal}>
            Connect Wallet
          </Button>
        )}
      </Box>
      {/* <div className="card-list">
        {cardItems.map(item => (
          <div key={item.title} className="card-item">
            <div className="icon-frame">
              <img src={item.icon} />
            </div>
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          </div>
        ))}
      </div> */}
    </main>
  )
}
